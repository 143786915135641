* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gral {
  /* width: 100vw; */
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-content: center;
}

.form1 {
  /* position: relative; */
  margin-top: 35px;
  background-color: white;
  width: 400px;
  /* height: 500px; */
  border-radius: 20px;
  padding: 30px 50px;
  box-shadow: 0 9px 16px rgba(83, 83, 83, 0.493);
  height: 85vh;
  /* margin: auto 0; */
}

.form1 small {
  color: red;
  width: 100%;
  animation-name: error;
  animation-duration: 3.1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: error;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form2 {
  /* position: relative; */
  margin: 15px 0;
  background-color: white;
  width: 400px;
  /* height: 500px; */
  border-radius: 20px;
  padding: 30px 50px;
  box-shadow: 0 9px 16px rgba(83, 83, 83, 0.493);
  /* margin: auto 0; */
}

/* .blikzlogo {
  margin-bottom: 10px;
} */

.form2 small {
  color: red;
  width: 100%;
  animation-name: error;
  animation-duration: 3.1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: error;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logo {
  display: flex;
  background-color: white;
  width: 130px;
  height: 110px;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 30px !important;
  box-shadow: inset 0 9px 16px rgba(179, 178, 178, 0.493);
  box-shadow: 0 9px 16px rgba(120, 120, 120, 0.493);
  justify-content: center;
  align-items: center;
}

.logo img {
  /* width: 140px;   */
  border-radius: 12px;
  max-width: 122px;
}

.title {
  margin: 0 auto;
  color: black;
  margin-top: 40px !important;
  margin-bottom: 30px !important;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.form1 input {
  border: solid 1.2px;
  border-color: rgb(164, 164, 164);
  border-radius: 13px;
  width: 320px;
  margin-top: 10px;
  /* margin-bottom: 15px; */
  height: 36px;
  padding-left: 15px;
  font-size: 16px;
  outline: none;
}

.input_div label {
  margin-bottom: 20px;
}

.form2 input {
  display: flex;
  justify-content: center;
  margin: 10px auto;
  width: 320px;
  height: 36px;
  align-content: center;
  border: solid 1.2px rgb(164, 164, 164);
  border-radius: 13px;
  font-size: 16px;
  outline: none;
  padding-left: 10px;
}

.input label {
  margin-top: 20px !important;
}

.form2 input::placeholder {
  /* padding-left: 20px; */
  font-size: 16px;
}

.inputimg {
  border: none !important;
  border-radius: 0 !important;
  font-size: 10px;
  width: 900px;
  /* margin-left: -15px !important;  */
}

.divButtons {
  display: flex;
  flex-direction: row;
}

.btn {
  margin-top: 20px;
  border-style: none;
  border-radius: 8px;
  cursor: pointer;
  width: 130px;
  height: 35px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}
.btn:hover {
  background-color: var(--color-blueblikz-btnhover);
}
.btnUser {
  width: 130px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid rgb(188, 188, 188);
  background-color: transparent;
  margin: 0 10px;
  cursor: pointer;
}

.active{
  border: 1.5px solid var(--color-blueblikz-principal);
}

.btnUserDiv {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 80px;
}

.tienda {
  margin-top: 4px;
  margin-bottom: 10px;
}
.consumidor {
  margin-bottom: 5px;
}

.btn:disabled {
  background-color: rgb(5, 55, 141);
  cursor: not-allowed;
}

.btn:disabled:hover {
  background-color: rgb(5, 55, 141);
}

.blikzlogo1 {
  margin-bottom: 20px;
}

.btn {
  /* margin-top: 20px; */
  border-style: none;
  border-radius: 8px;
  cursor: pointer;
  width: 130px;
  height: 35px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.btn:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.btn2 {
  border-style: none;
  border-radius: 8px;
  cursor: pointer;
  width: 130px;
  height: 35px;
  background-color: var(--color-blueblikz-principal);
  color: white;
  margin: 20px 20px;
}
.btn2:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.btn2:disabled {
  background-color: rgb(5, 55, 141);
  cursor: not-allowed;
}

.btn2:disabled:hover {
  background-color: rgb(5, 55, 141);
}

@media screen and (max-width: 423px) {
  .form1,
  .form2 {
    width: 340px;
  }

  .form1 input,
  .form2 input {
    width: 280px;
  }
}
