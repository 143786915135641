.gral {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gral img {
  margin-bottom: 35px;
}

.close1 {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 70px;
  margin-top: 50px;
  cursor: pointer;
  background-color: transparent;
  /* color: rgb(182, 182, 182); */
  border: none;
  font-size: 26px;
  font-weight: 400;
}

/* .inputsDiv{
    display: flex;
    flex-direction: column;
  }
  
  .infoDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 0 !important;
  }
  
  .infoDiv img{
    margin-top: 40px;
    margin-right: 20px;
  } */

.title {
  margin-top: 30px;
  margin-bottom: 80px;
  font-weight: 500;
  font-size: 29px;
}

.form {
  margin-top: 20px;
}

/* .input {
    background-color: rgb(219, 219, 219);
    border: none;
    height: 45px;
    width: 400px;
    border-radius: 3px;
    outline: none;
    padding-left: 15px;
    font-size: 17px;
    cursor: pointer;
    margin: 20px 0;
  }
  
  .input:hover {
    background-color: rgb(206, 206, 206);
  }
  
  .input::placeholder {
    color: rgb(36, 36, 36);
    font-size: 17px;
  } */

.buttons button {
  margin: 0 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.buttons button:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.buttons button:disabled {
  background-color: var(--color-blueblikz-btnhover);
}

.btnModal {
  margin-top: 20px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 140px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.btnModal:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.btn {
  margin: 30px 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 200px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.btn:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.btn:disabled {
  background-color: rgb(5, 55, 141);
  cursor: not-allowed;
}

.active {
  background-color: rgb(29, 29, 77) !important;
}

.costoEnvio {
  margin-top: 30px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
  height: 50px;
  width: 450px;
  padding: auto 0 !important;
  display: flex;
  justify-content: center;
  /* padding: 10px; */
  /* padding-top: 10px; */
}

.costoEnvio h1 {
  font-weight: 400;
  font-size: 23px;
  margin: auto 0 !important;
}

.costoEnvio h3 {
  font-weight: 600;
  font-size: 23px;
  margin-left: 20px !important;
  margin: auto 0;
}

.costoEnvio h4 {
  font-weight: 300;
  margin: auto 0 !important;
}

.largaDistancia {
  color: red;
  font-weight: 400 !important;
}

.titlePagado {
  margin-top: 30px;
}

.gralProductoPagado {
  margin-top: 200px;
}

.gralProductoPagar {
  margin-top: 120px;
}

.gralProductoPagado h2,
.gralProductoPagar h2 {
  margin-bottom: 35px;
}

.gralProductoPagado button,
.gralProductoPagar button {
  margin: 30px 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 200px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.gralProductoPagado button:disabled,
.gralProductoPagar button:disabled {
  background-color: rgb(5, 55, 141);
  cursor: not-allowed;
}

.switchgral {
  display: flex;
  flex-direction: row;
}

.switchbutton {
  display: inline-block;
  margin-bottom: 20px;
}
.switchbutton .switchbutton_checkbox {
  display: none;
  cursor: pointer;
}
.switchbutton .switchbutton_label {
  background-color: var(--color-blueblikz-principal);
  width: 75px;
  height: 37px;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.switchbutton .switchbutton_label:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 37px;
  height: 37px;
  background-color: white;
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px rgb(180, 180, 180);
  cursor: pointer;
}

.switchbutton .switchbutton_checkbox:checked + .switchbutton_label {
  background-color: var(--color-blueblikz-principal);
}
.switchbutton .switchbutton_checkbox:checked + .switchbutton_label:before {
  transform: translateX(2.4rem);
}

.labels {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.labels p {
  margin: 0 60px;
  transform: translateY(28px) translateX(5px);
  font-weight: 500;
  font-size: 18px;
}

.map {
  height: 100%;
  width: 36%;
}

.inputName {
  background-color: transparent;
  height: 50px;
  outline: none;
  padding-left: 15px;
  background-color: rgb(219, 219, 219);
  border: none;
  border-radius: 3px;
  font-size: 17px;
  color: rgb(36, 36, 36);
  cursor: pointer;
  width: 250px;
  /* align-content: center; */
}

.inputName:hover {
  background-color: rgb(206, 206, 206);
}

.inputName::placeholder {
  color: rgb(36, 36, 36);
  font-size: 16px;
  text-align: center;
  padding-right: 15px !important;
}

.cliente {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
}

.telefono {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  background-color: rgb(219, 219, 219);
  border: none;
  height: 45px;
  width: 445px;
  border-radius: 3px;
  outline: none;
  padding-left: 15px;
  font-size: 17px;
  cursor: pointer;
}

.telefono:hover {
  background-color: rgb(206, 206, 206);
}

.telefono::placeholder {
  color: rgb(36, 36, 36);
  font-size: 17px;
}

.select{
  margin: 10px auto;
  background-color: rgb(219, 219, 219);
  border: none;
  height: 45px;
  width: 445px;
  border-radius: 3px;
  outline: none;
  padding-left: 10px;
  font-size: 17px;
  cursor: pointer;
}

.detalle {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  margin-bottom: 40px !important;
  background-color: rgb(219, 219, 219);
  border: none;
  height: 110px;
  width: 445px;
  border-radius: 3px;
  outline: none;
  padding: 0 15px;
  font-size: 17px;
  cursor: pointer;
  padding-bottom: 65px;
  display: inline-block;
}

.detalle:hover {
  background-color: rgb(206, 206, 206);
}

.detalle::placeholder {
  color: rgb(36, 36, 36);
  font-size: 17px;
}

.check {
  background: #F4F4F4;
  height: 100vh;
}
.check img{
  margin-top: 140px;
}

/* .check img{
    height:"300px"
  } */

.inputpagado {
  background-color: transparent;
  height: 50px;
  outline: none;
  padding-left: 15px;
  background-color: rgb(219, 219, 219);
  border: none;
  border-radius: 3px;
  font-size: 17px;
  color: rgb(36, 36, 36);
  cursor: pointer;
  width: 250px;
  margin: 20px 0;
  text-align: center;
  /* align-content: center; */
}

.inputpagado:hover {
  background-color: rgb(206, 206, 206);
}

.inputpagado::placeholder {
  color: rgb(36, 36, 36);
  font-size: 16px;
  text-align: center;
  padding-right: 15px !important;
}
.popupDiv {
  position: fixed;
  top: -13px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(7px);
  z-index: 5555;
}

.popup {
  margin-top: 120px;
}

.popup input {
  margin-top: 20px;
  margin-bottom: 30px;
}
.title25 {
  margin-top: 30px;
}
.botonPiso {
  margin-top: 110px;
  /* margin: 30px 5px; */
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 200px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.botonPiso:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.quienAbona {
  margin-top: 30px;
}

.piso {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  background-color: rgb(219, 219, 219);
  border: none;
  height: 45px;
  width: 400px;
  border-radius: 3px;
  outline: none;
  padding-left: 15px;
  font-size: 17px;
  cursor: pointer;
}
.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -36px;
  margin-right: 15px;
  z-index: 5;
}

.close button {
  font-weight: 300;
  font-size: 23px;
  color: red;
  background: none;
  border: none !important;
  cursor: pointer;
}

.piso:hover {
  background-color: rgb(206, 206, 206);
}

.interDiv {
  display: flex;
  flex-direction: column;
}

.buttonsMobile {
  margin-top: 70px;
}

.cotizadorDiv {
  margin-top: 150px;
}

.cotizadorDiv h3 {
  font-weight: 500;
  font-size: 21px;
  margin-top: 20px;
}

.precioDiv{
  margin-top: 180px;
}

.precioDiv h2{
  margin-bottom: 30px;
}

.precioButton {
  margin: 0 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.precioButton:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.precioButtonCancelar{
  margin: 0 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: rgb(225, 10, 10);
  color: white;
}

.precioButtonCancelar:hover {
  background-color: rgb(182, 4, 4);
}

.precioEnvio{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.precioEnvio img{
  margin: 40px 5px;
}

.precioEnvio h2{
  margin: 40px 5px;
  margin-bottom: 60px;
  /* margin-bottom: 50px; */
}

.costoEnvio {
  /* margin-top: 30px;
  margin-bottom: 10px; */
  margin: 0 auto;
  background-color: rgb(219, 219, 219);
  border-radius: 3px;
  height: 50px;
  width: 450px;
  /* padding: auto 0 !important; */
  /* display: flex;
  justify-content: center; */
  /* padding: 10px; */
  /* padding-top: 10px; */
}

.costoEnvio h1 {
  font-weight: 400;
  font-size: 23px;
margin: auto 0 !important;
}

.costoEnvio h3 {
  font-weight: 600;
  font-size: 23px;
  margin-left: 20px !important;
  margin: auto 0 ;
}

.costoEnvio h4{
  font-weight: 300;
  margin: auto 0 !important;

}


@media screen and (max-width: 780px) {
  .buttonsMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .buttonsMobile button {
    width: 150px;
  }
  .gralProductoPagado {
    padding: 0 10px;
  }
  .map {
    width: 100%;
  }
  .inputRetiroEntrega {
    margin: 0 auto !important;
  }
  .costoEnvio {
    width: 90%;
    flex-direction: row;
  }
  .costoEnvio h1 {
    width: 300px;
    font-size: 18px;
    margin-left: 20px !important;
  }
  .costoEnvio h3 {
    font-size: 20px;
    width: 100%;
  }
  .inputName {
    width: 75%;
    margin-right: 10px !important;
  }
  .cliente {
    width: 95%;
  }
  .telefono {
    width: 320px;
  }
  .select{
    width: 320px;
  }
  .detalle {
    width: 320px;
  }
  .cliente img {
    height: 145px !important;
  }
  
  .check img {
    width: 100%;
    height: 240px;
    margin-top: 220px !important;
  }
  
  .cotizadorDiv h3{
   margin: 0 20px;
  }
}
