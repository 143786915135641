.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    backdrop-filter: blur(7px);
  }
  
  .title {
    display: flex;
    margin: 0 auto;
    color: black;
    margin-bottom: 40px !important;
    font-size: 23px;
    font-weight: 500;
    /* margin-left: 5px;*/
    margin-top: 27px !important;
    /* padding: 10px ; */
    padding-top: 10px;
    /* padding-right: 30px; */
    /* margin-right: 68px; */
    }
  
  .divTitle {
    display: flex;
    justify-content: space-between;
  }
  
  .close {
    position: absolute;
    width: 23px;
    height: 24px;
    border-radius: 5px;
    top: 0;
    right: 0;
    margin-top: 15px;
    margin-right: 20px;
    /* margin-top: -3%;
    margin-right: -2%; */
    cursor: pointer;
    background-color: transparent;
    color: rgb(182, 182, 182);
    border: none;
    font-size: 23px;
    font-weight: 400;
  }
  
  .close:hover {
    color: rgba(231, 53, 53, 0.877);
  }
  
  .form {
    position: relative;
    width: 450px;
    padding: 40px;
    margin: auto 0;
    margin-top: 3rem;
    border-radius: 20px;
    border-color: black;
    color: black;
    font-size: 14px;
    /* margin-bottom: 20px; */
    -webkit-box-shadow: 0px 9px 13px -2px #949393;
    box-shadow: 0px 9px 13px -2px #6d6d6d;
    background-color: white;
    }
  
    .div_input label{
      font-weight: 500;
    }
  
    .form input {
      border: solid 1.2px;
    border-color: rgb(164, 164, 164);
    border-radius: 13px;
    width: 320px;
    margin-top: 10px;
    margin-bottom: 15px;
    height: 36px;
    padding-left:15px;
    font-size: 16px;
    outline: none;
    }
  
    .btn {
      margin-top: 40px;
      border-style: none;
      border-radius: 8px;
      cursor: pointer;
      width: 130px;
      height: 35px;
      background-color: var(--color-blueblikz-principal);
      color: white;
    }
    
    .btn:hover{
      background-color: var(--color-blueblikz-btnhover);
    }
  
    