.gral {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}

.gral h2 {
  font-weight: 500;
}

.gral img {
  border-radius: 80px;
  margin-bottom: 50px;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}

.buttons button {
  background-color: var(--color-blueblikz-principal);
  color: white;
  border: none;
  height: 43px;
  width: 150px;
  margin: 0 40px;
  border-radius: 13px;
  font-weight: 500;
  cursor: pointer;
}

.gralError{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 100px;
}

.gralError h3{
  font-size: 21px;
  font-weight: 500;
 margin-top: 50px;
}

