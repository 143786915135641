.container {
    height: 100%;
    /* position: relative; */
    top: 0;  
    margin-top: 15px;
    font-family: "Montserrat";
    margin-bottom: 100px;
}

.header{
    margin-bottom: 20px;
}

.input_box {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    height: 200px;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);
}
  /* contenedor del input y el icon */
  .input {
    width: 100%;
  }

  .input input {
    border-radius: 8px;
    border: none;
    height: fit-content;
    padding: 15px;
    width: 98%;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);
  }

  .icon {
    margin-left: -30px;
    margin-right: 20px;
    align-content: center;  
    cursor: pointer;
    cursor: pointer;
  }

  .icon:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  .selects_box {
    width: 98%;
    display: flex;
    padding: 5px;
    font-family: "Montserrat";
    margin:40px 0;
  }

  .title h1{
    display: flex;
    justify-content: flex-start;
    color: black;
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 500;
    padding-left: 10px;
    margin: auto 0;
    margin-top: 60px;
    margin-bottom: 50px;
    /* margin-bottom: 40px; */
  }

  .filters{
    /* position:absolute; */
    /* bottom: 526px;
    right: 0; */
    background-color: white;
    color: white;
    /* align-items: center; */
    width: 510px;
    height: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .selects{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-color: red; */
    
  }

  .selects select {
    /* background-color: var(--color-blueblikz-principal); */
    margin: auto 6px;
    border-radius: 20px;
    /* color: #ffffff; */
    font-size: 14px;
    text-align: center;
    align-content: center;
    /* padding: 13px; */
    height: 38px;
    width: 150px;
    border-style: none;
    cursor: pointer;
    /* box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4); */
  }

  .selects select:focus{
   outline: none;
  }  
  .selects select:hover{
    background-color: var(--color-blueblikz-principal);
    color: white;
    height: 36px;
  }
  .selects select option {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }

  .selects_box select {
    background-color: var(--color-blueblikz-principal);
    margin: 0 10px;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    /* padding: 13px; */
    height: 38px;
    width: 150px;
    border-style: none;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);
  }
  
  .selects_box select:hover{
    background-color: var(--color-blueblikz-btnhover);
  }
  .selects_box select option {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  
  /* ----Tables---------- */
  /* .table{
    box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
    border-radius: 12px;
  } */

  .table_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
    height: fit-content;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);
    /* background-color: #ddd; */
    
  }
 
    table {
      border-spacing: 0;
      border-collapse: collapse;
      border: none;
      width: 870px;
    }

    .topRowRight{
      border-top-right-radius: 10px;
    }

    /* .topRowLeft{
      border-bottom-left-radius: 10px;
    } */

    /* table thead tr{
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    } */
  
  table thead tr th {
    background-color: var(--color-blueblikz-principal);
    width: 178px;
    font-weight: 500;
    color: white;
    height: 45px;
  }

  table tbody tr td {
    background-color: white;
    height: 50px;
    font-size: 15px;
  }

  .tabletop{
    background-color: var(--color-blueblikz-principal);
    font-weight: 500;
    height: 45px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    /* margin: 0 auto; */
  }
  /* .table thead tr{
    background-color: var(--color-blueblikz-principal);
  } */

  .head{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: var(--color-blueblikz-principal);
    color: white;
    font-weight: 500;
    font-family: "Montserrat";
    width: 900px;
    height: 45px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border-top-right-radius: 10px; */
  }

  .head img{
    margin-left: 50px;
  }
  
  /* -----------t body-------- */
  .table_container table tbody tr td {
    padding: 18px;
    background-color: rgb(250, 250, 250);
    font-size: 14px;
  }
  .notMatch {
  background-color: rgba(197, 192, 192, 0.521);
    width: 100%;
    text-align: center;
    color: red;
  }
  /* .refresh {
    background-color: var(--color-principal-violet);
    border-radius: 4px;
    border: 1px solid #124d77;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 14px;
    padding: 13px;
    height: 43px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #154682;
    margin: 0 15px;
    border-style: none;
  
  }
  .refresh:hover{
    background-color: var(--color-principal-violet-hover);
  }
  .refresh:active {
    position: relative;
    top: 1px;
  } */
  .edit{
    cursor: pointer;
  }
  .edit:hover{
    transform: scale(1.4);
    cursor: pointer;
  }
  .update_container{
    position:absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.596);
    backdrop-filter: blur(7px);
    z-index: 99999999;
  
  }
  .disabled{
    color: rgb(185, 164, 164);
    pointer-events: none;
  }
  .activate_btn,
  .activate_btn2 {
    padding:7px;
    border: none;
    border-radius: 3px;
    background-color: green;
    cursor: pointer;
    font-weight: bold;
    color: white
  }
  .disabled_btn,
  .disabled_btn2 {
    padding:3px;
    background-color: rgb(223, 48, 48);
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .activate_btn2:disabled,
  .disabled_btn2:disabled {
    background: #333;
    opacity: 0.4;
    cursor: default;
  }
  
  .select {
    padding: .5rem;
    border-radius: .5rem;
    text-align-last: center;
  }

  .textNotProducts{
    color: black;
    margin: 50px auto;
    font-weight: 400;
    font-size: 22px;
    /* padding-bottom: 50px; */
    /* align-content: center; */
    /* width: 20px; */
  }
  
  .container1{
    font-family: "Montserrat";
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    width: 900px;
    background-color: white;
    font-size: 14px;
  }
  
  .datosi{
    margin-left: 10px;
    margin-right: 25px;
  }
  
  .clientes{
    /* margin-right: -30px; */
    margin-left: -15px;
  }
  
  .envio{
    margin-right: -50px;
    /* margin-left: 30px; */
  }

  .fecha{
    margin-left: -5px;
  }

  .container button{
    margin: auto 0;
    font-weight: 500;
    /* margin-left: 8px !important; */
    color: var(--color-blueblikz-principal);
    background: none;
    border: none;
    cursor: pointer;
}

.container button:hover{
    color: var(--color-blueblikz-btnhover);
    background: none;
    border: none;
}

.status{
  border-top-right-radius: 10px;
}

@media screen and (max-width: 1226px) {
  table{
   width: 870px;
   /* margin-left: 40px; */
  }
  .filters {
    /* margin-left: 40px; */
  }
 }

 @media screen and (max-width: 1193px) {
   table{
       width: 840px;
       /* marginleft-: 40px; */
   }
   .filters{
    /* margin-left: 40px; */
  }
 }

 @media screen and (max-width: 1170px) {
   table{
       width: 820px;
       /* margin-left: 40px; */
   }
   .filters{
    /* margin-left: 40px; */
  }
 }

 @media screen and (max-width: 1150px){
   table{
       width: 750px;
   }
}

@media screen and (max-width: 780px){
  table{
    width: 700px;
  }
}

@media screen and (max-width: 750px){
  table{
    width: 600px;
  }
  .filters{
    width: 445px;
  }
}

@media screen and (max-width: 650px){
  table{
    width: 500px;
  }
}

@media screen and (max-width: 500px){
  table{
    width: 400px;
  }
  .filters{
    width: 290px;
  }
}

@media screen and (max-width: 420px){
  table{
    width: 340px;
  }
}