.topBanner {
  z-index: 100;
  margin-bottom: -90px;
}
.retiro {
  margin-right: 30px !important;
}
.buttonNuevoP {
  margin: 0 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: var(--color-blueblikz-principal);
  color: white;
  margin-bottom: 30px;
}

.buttonNuevoP:hover {
  background-color: var(--color-blueblikz-btnhover);
}
.gifDivGral {
  margin-top: 160px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cardInformation {
  background-color: hsl(0, 0%, 99%);
  padding: 20px 0;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
  font-family: "Montserrat";
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.time img {
  margin-right: 5px;
}

.timeText {
  font-weight: 500;
  margin-bottom: 10px;
}
.buttonCancelar {
  background-color: rgb(225, 10, 10);
  margin: 0 5px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 120px;
  height: 40px;
  color: white;
  margin-bottom: 30px;
}

.direccionEntrega {
  font-weight: 500;
  margin-bottom: 5px;
}

.costoEnvio {
  /* margin-bottom: 10px; */
  font-weight: 500;
}

.precio {
  /* font-weight: 500; */
  margin-bottom: 10px;
  margin-top: 5px;
}

.mensaje {
  margin-top: -20px;
  margin-bottom: 40px;
}

.gralError {
  margin-top: 170px;
}

.gralError img {
  margin-bottom: 20px;
}

.gralError h3 {
  margin-bottom: 80px;
}
.pedidoCancelado{
  margin-top: 170px;
}
.pedidoCancelado img {
  margin-top: 80px;
  margin-bottom: 50px;
}
.pedidoEntregado {
  margin-top: 170px;
}

.pedidoEntregado img {
  margin-bottom: 20px;
}
.pedidoEntregado h3 {
  margin-bottom: 80px;
}

@media screen and (max-width: 780px) {
  .loaders {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
  .imgDiv {
    top: 165px;
    left: 35px;
    margin-top: -15px;
  }
  .cardInformation {
    width: 90%;
    padding: 20px 10px;
    margin-bottom: 70px;
    text-align: left;
  }
  .retiro {
    margin-left: 0px !important;
  }

  .repartidor {
    margin-left: 35px !important;
  }
  .entrega {
    margin-left: -10px !important;
  }
  .direccionEntregaInfo {
    margin-left: 10px;
  }
  .divButtons {
    display: flex;
    flex-direction: row;
  }
  .buttonCancelar {
    margin: 0 auto;
    width: 180px;
    height: 42px;
    margin-top: 170px;
  }
  /* .repartidorImg{
    margin-left: 7px !important;
    margin-top: -8px !important;
} */
}
