.gral img {
  margin-top: 35px;
  /* color: var(--color-blueblikz-principal); */
}

.gral h2 {
  color: black;
  margin: 0 auto;
  margin-top: 50px !important;
  margin-bottom: 30px !important;
  font-weight: 500;
  font-size: 20px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  margin: 0 10px;
  padding-right: 15px;
  height: 100px;
}

.containerButton {
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: auto 5px;
  width: 150px;
  height: 45px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.containerButton:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.containerButton:disabled {
  background-color: var(--color-blueblikz-disabled);
}

.whatsapp {
  background-color: rgb(29, 214, 23) !important;
}

.container select {
  width: 200px;
  height: 50px;
  margin-right: 10px !important;
  outline: none;
  border: none;
  background-color: rgb(234, 234, 234);
  border-radius: 0.25rem;
  color: black;
  padding-left: 17px;
  cursor: pointer;
}

.container h2 {
  font-weight: bold;
}

.container img {
  margin: auto 0;
  padding-right: 25px;
  padding-left: 25px;
}

.buttonPedido {
  font-weight: 500;
  color: var(--color-blueblikz-principal);
  background: none;
  border: none;
  font-size: 17px;
  cursor: pointer;
}

.buttonPedido:hover {
  color: var(--color-blueblikz-btnhover);
  background: none;
  border: none;
}

.container1 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-content: center;
  background-color: white;
  border-radius: 12px;
  margin: 20px 10px;
  height: 100px;
}

.tienda_div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-right: 90px; */
  margin-left: 35px;
}

.tienda_div1 img {
  margin: auto 0;
  padding-right: 25px;
  padding-left: 23px;
}

.tienda_div1 h3 {
  padding-left: 20px;
  padding-right: 65px;
  margin: auto 0;
  font-size: 21px;
}

.tienda_div1 h4 {
  margin-top: 3px;
  font-weight: 500;
  font-size: 18px;
  margin-right: 50px;
}

.tienda_div1 button {
  margin: auto 0;
  font-weight: 500;
  margin-left: 30px !important;
  color: var(--color-blueblikz-principal);
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 200px !important;
}

.tienda_div1 button:hover {
  color: var(--color-blueblikz-btnhover);
  background: none;
  border: none;
}

.inputPrecio input {
  height: 50px;
  width: 130px;
  padding-left: 17px;
  outline: none;
  border: none;
  background-color: rgb(234, 234, 234);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: black;
}


.inputPrecio input::placeholder {
color: black;
}

.inputPrecio button{
    height: 50px;
    width: 70px;
    border: none;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    background-color: var(--color-blueblikz-principal);
    color: white;
    cursor: pointer;
}

.inputPrecio button:hover {
background-color: var(--color-blueblikz-btnhover);
}

.inputPrecio button:disabled {
    background-color: var(--color-blueblikz-disabled);
  }

.inputPrecio input:disabled {
  background-color: rgb(183, 183, 183);
}

.buttonOff{
  display: none;
}

.buttonWs{
  background-color: #25D366;
  border: none;
  color: white;
  height: 35px;
  border-radius:  5px; 
  margin: 0 20px;
  padding: 0 10px;
}

.buttonWs:hover{
  background-color: #1eb455;
  cursor: pointer;
}