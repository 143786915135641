
.main_box {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end;
  margin-top: 43px;
  margin-left: 400px;
  width: 700px;
  min-height: 95vh; */
}

/* .main_container section{
  display: flex;
  flex-flow: column;
  width: 70%;
  flex-grow: 1;
  border-radius: inherit;
  margin-top: -110px;
} */

.div_aside{
  width: 28.5%;
}

.div_section{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

body::-webkit-scrollbar{
  width: 10px;
  }
  
  body::-webkit-scrollbar-thumb{
      background-color: rgb(173, 173, 173);
      border-radius: 6px;
  }

  /* .mobile_div{
    margin: 0 auto;
  } */

  .div_mobilemenu{
      display: none;
  }
   
  @media screen and (max-width: 1080px){
    .div_mobilemenu{
      display: flex;
    }

    .div_aside{
      display: none;
    }

  }