.gral {
  position: fixed;
  bottom: 0;
  margin: 0 auto;
}

.arrow {
  /* background-color: rgb(24, 24, 24); */
  background-color: rgb(212, 212, 212);
  width: 100px;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 12px;
  cursor: pointer;
}

.box {
  width: 300px;
  /* background-color: rgb(24, 24, 24); */
  background-color: rgb(212, 212, 212);
  /* color: rgb(212, 212, 212); */
  height: 25px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-top: 7px;
  /* cursor: pointer; */
  font-size: 14px;
}

.open {
  /* width: 500px; */
  /* background-color: rgb(24, 24, 24); */
  background-color: rgb(212, 212, 212);
  /* background-color: red !important; */
  /* color: rgb(212, 212, 212); */
  /* height: 200px; */
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-top: 7px;
  /* cursor: pointer; */
  font-size: 14px;
}
