.gral {
  background-color: #fdfdfd;
  height: 140px;
  width: 900px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  /* justify-content: left; */
  align-items: center;
  box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
  font-family: "Montserrat";
  margin-top: 30px;
}

.logo img {
  height: 90px;
  margin-left: 40px;
  margin-right: 210px;
}

.texto {
  /* width: 140px; */
  /* margin-left: 130px; */
}

.texto h1 {
  color: black;
  font-size: 35px;
  font-weight: 500;
}

.texto h4 {
  font-weight: 500;
  color: var(--color-blueblikz-principal);
  margin-bottom: 5px;
  text-align: center;
  font-size: 20px;
}

@media screen and (max-width: 1226px) {
  .gral {
    width: 870px;
  }
}

@media screen and (max-width: 1193px) {
  .gral {
    width: 840px;
  }
}

@media screen and (max-width: 1170px) {
  .gral {
    width: 820px;
  }
}

@media screen and (max-width: 1150px) {
  .gral {
    width: 750px;
  }
}

@media screen and (max-width: 780px) {
  .gral {
    width: 700px;
  }
}

@media screen and (max-width: 750px) {
  .gral {
    width: 600px;
    justify-content: center;
    align-items: center;
    height: 120px;
  }
  .texto h1 {
    font-size: 30px;
    text-align: center;
  }
  .texto h4 {
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .gral {
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .gral {
    width: 400px;
  }

  .logo {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .gral {
    width: 340px;
  }
  /* .btn1, .btn2{
        width: 135px;
    } */
}
