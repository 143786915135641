.inputsDiv {
  display: flex;
  flex-direction: column;
  position: relative;
}

.infoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto 0 !important;
}

.infoDiv img {
  margin-top: 40px;
  margin-right: 20px;
}

.input {
  background-color: rgb(219, 219, 219);
  border: none;
  height: 45px;
  width: 400px;
  border-radius: 3px;
  outline: none;
  padding-left: 15px;
  font-size: 17px;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
}

.input:hover {
  background-color: rgb(206, 206, 206);
}

.input::placeholder {
  color: rgb(36, 36, 36);
  font-size: 17px;
}

.inputLine {
  border-left: 2px solid rgb(188, 187, 187);
  height: 20px;
}

.btnMiUbic{
  position: absolute;
  background-color: transparent !important;
  bottom: 23px;
  left:350px;
}
.btnMiUbic2{
  position: absolute;
  background-color: transparent !important;
  top: 110px;
  left:350px;
}

.btnMiUbic img{
  background-color: transparent !important;
}
.inputPuerta {
  background-color: rgb(180, 180, 180);
  margin-bottom: 20px;
  height: 40px;
  width: 70%;
  border-radius: 3px;
  border: none;
  outline: none;
  padding-left: 15px;
  font-size: 15px;
  cursor: pointer;
  color: black;
}

.inputPuerta:hover {
    background-color: rgb(198, 198, 198);

}

.inputPuerta::placeholder {
    color: black;
  }

.inputDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputDiv button {
  position: absolute;
  background: rgb(206, 206, 206);
  border: none;
  font-size: 20px;
  padding: 10px 15px;
  margin-right: 0.4px;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media screen and (max-width: 780px) {
  .infoDiv {
    /* width: 70%; */
    justify-items: center;
    width: 300px;
    justify-content: center;
    margin: 0 10px !important;
  }
  .inputsDiv {
    width: 100%;
    margin: 0 auto !important;
  }
  .input{
    width: 270px;
  }
}
