.gral{
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    background-image: url("../../../../assets/final.png"); 
    background-position:bottom;
    background-size: cover;
    height: 140px;
    width: 900px;
    border-radius: 15px;
    box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
    margin-top: 15px;
}


.gral h2{
    color:rgba(255, 255, 255, 0.9);
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    margin-left: 25px;
    margin-top: 115px;
    text-align: left;
    bottom: 0
    /* margin-top: 57px; */
}

.ws{
    /* position: absolute; */
    /* display: flex; */
    /* justify-content: flex-end; */
    /* right: 60px;
    top: 30px; */
    /* margin-left: 20px; */
    margin-top: 10px !important;
    margin-right: 15px;
    cursor: pointer;
    /* z-index: 9999999999; */
  }

  @media screen and (max-width: 1226px) {
   .gral{
    width: 870px;
   }
  }

  @media screen and (max-width: 1193px) {
    .gral{
        width: 840px;
    }
  }

  @media screen and (max-width: 1170px) {
    .gral{
        width: 820px;
    }
  }

  @media screen and (max-width: 1150px){
    .gral{
        width: 780px;
    }
}
  @media screen and (max-width: 1150px){
    .gral{
        width: 750px;
    }
  }

  @media screen and (max-width: 850px){
    .gral h2{
      /* margin: auto 0;
      line-height: 35px;
      width: 400px; */
    }
  }

  @media screen and (max-width: 780px){
    .gral{
      width: 700px;
    }
  }

  @media screen and (max-width: 750px){
    .gral{
      width: 600px;
    }

    .ws{
      height: 30px;
    }
  }
  
  @media screen and (max-width: 650px){
    .gral{
      width: 500px;
    }
  }

  @media screen and (max-width: 500px){
    .gral{
      width: 400px;
    }
  }

  @media screen and (max-width: 420px){
    .gral{
      width: 340px;
      height: 120px;
    }
    .gral h2{
      /* width: 260px;
      font-size: 16px; */
      margin-top: 100px;
      font-size: 11.5px;

    }
    .ws{
      height: 25px;
    }
  }
