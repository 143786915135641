.container {
    position: relative;
    margin: 15px 22px;
    display: flex;
    justify-content: center;
  }
  
  .title {
    width: 70vw;
    margin: 80px 15vw 20px 15vw;
    font-size: 20px;
    font-weight: bold;
  }
  
  .container .carousel {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    position: relative;
    margin-bottom: 80px;
  }
  
  .container .carousel::-webkit-scrollbar {
    display: none;
  }
  
  .container .carousel .item {
    background-color: white;
    width: 270px;
    justify-content: space-around;
  }
  
  .btnIzq {
    background: none;
    border: none;
  }
  
  .btnDer {
    background: none;
    border: none;
  }
  
  .btn {
    margin-top: 15px;
    justify-content: center;
  }
  