.page {
}

.page h2{
    color: black;
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 30px;
    /* margin-left: 0px;
    margin-right: 100px; */
}

.box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin: 0 auto; */
    /* margin: 3%; */
}

.button {
    background-color: white;
    border: solid 1px rgb(212, 212, 212);
    border-radius: 8px;
    padding: 18px;
    width: 180px;
    box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
    cursor: pointer;
    margin: 0 20px;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.button h3{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: black;
    font-weight: 500;
}

.button:hover {
    background-color: rgb(245, 245, 245);
}

.chart {
    border-radius: .25rem;
    background-color: var(--color-blueblikz-principal);
    height: 40px;
    width: 850px;
    color:white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: normal;
    margin: 0 auto;
}

.chart h2{
    display: flex;
    margin: auto 60px;
    color: white;
    font-size: 18px;

}

.chart h2 img{
    margin-left: 10px;
    cursor: pointer;
    position: relative;
}

.cuadrotexto h3{
    position: absolute;
    bottom: 452px;
    left: 600px; 
    color: white;
    z-index: 99;
    font-size: 13px;
    font-weight: 500;
}

.cuadrotexto img{
    position: absolute;
    left: 580px;
    bottom: 415px;
    z-index: 9;
}

.details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    color: black;
    justify-content: space-around;
    background-color: white;
    margin: 0 40px;
    height: 75px;
    border-radius: 12px;
    box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
    max-width: 900px;
}

.details h2{
    font-size: 17px;
    margin: auto 20px;
}

.details img{
    margin: auto 0;
    margin-right: 20px !important;
    margin-left: 15px;
}

.details button {
    color: red;
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
    margin-right: 15px;
}

.details button img{
    margin-left: 20px;
}

.details button:hover{
transform: scale(1.1);
}

@media screen and (max-width: 450px){
    .button{
      width: 130px;
    }
}

@media screen and (max-width: 895px){
    .details{
        height: 140px;
    }

    .details button{
        display: none;
    }
    .details img{
        display: none;
    }
}