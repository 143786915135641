.gral {
  background-color: var(--color-blueblikz-principal);
  margin-top: 20px;
}

@media screen and (max-width: 1080px) {
  .gral {
    position: fixed;
    bottom: 0;
    height: 65px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
    color: white;
    font-size: 38px;
    font-weight: 200;
    z-index: 9;
  }
  .boton1 {
    background: none;
    border: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 400px) {
  .gral {
    width: 100%;
  }

  .boton1 img {
    height: 25px;
  }
}
