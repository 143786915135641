.pedidoEntregado {
  margin-top: 170px;
}

.pedidoEntregado img {
  margin-bottom: 20px;
}

.pedidoEntregado h3 {
  margin-bottom: 80px;
}

.gralError {
  margin-top: 130px;
}

.gralError img {
  margin-bottom: 20px;
}

.gralError h3 {
  margin-bottom: 80px;
}

.title {
  margin-top: 30px;
  font-weight: 500;
  font-size: 26px;
  text-align: left;
}

.subtitle {
  margin-top: 20px;
  text-align: left;
  font-weight: 400;
  color: rgb(98, 98, 98);
}
.infoDiv {
  border-bottom: 1px solid rgb(98, 98, 98);
}
.info {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 400;
}
.link {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 400;
  color: rgb(0, 102, 255);
  text-decoration-line: underline;
}
.numeroTelefonoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.numeroTelefonoDiv h4{
margin-right: 7px;
}
.divButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;
}

.divButtons button {
  padding-bottom: 60px;
}

.buttons {
  margin: 0 70px;
  height: 190px;
  width: 220px;
  border-radius: 12px;
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: rgb(224, 222, 222);
}

.buttons:hover {
  box-shadow: 0 9px 16px rgba(94, 94, 94, 0.493);
  border: none;
}

.buttons h4 {
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
}

.buttonDis {
  margin: 0 70px;
  height: 190px;
  width: 220px;
  border-radius: 12px;
  padding: 10px;
  border: none;
  background-color: rgb(172, 172, 172);
}

.buttonDis h4 {
  margin-top: 20px;
  margin-bottom: -30px;
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.gral {
  padding: 0 20px;
}

@media screen and (max-width: 780px) {
  .buttons {
    margin: 0 10px !important;
    height: 100px;
  }
  .buttonDis {
    margin: 0 10px !important;
    height: 100px;
    /* padding: 10px; */
  }
  .buttonDis h4 {
    margin-bottom: 20px;
  }
  .divButtons {
    margin-top: 140px;
  }
  .imgButtons {
    height: 40px !important;
  }
  .title {
    margin-bottom: 50px;
  }
  .pedidoEntregado {
    margin-top: 280px;
  }
  .imgEntregado {
    height: 160px;
  }
  .mensaje {
    padding: 0 60px;
  }
}
