body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: transparent; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-blueblikz-principal: #5273F0;
  --color-blueblikz-btnhover: #4463d3;
  --color-blueblikz-disabled: #2e3a61;
  --color-bg-footer: #171516;
  --color-subtitle: #4B5C61;
  --color-button-principal: #5273F0;
  --color-button-hover: #2851EC;
  --color-button-second: #E3E9FF;
  --color-button-hover2: #E8EDFD;
  --color-footer-text: #B3B3B3;
  --color-footer-title: #f3f3f3;
  --color-footer-background: #171616;
}