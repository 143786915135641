.gral{
    left: 0px;
    position: fixed;
    top:0px;
    height: 100%;
    margin-top: 8px;
}

.logo{
height: 35px;
margin-top: 20px;
}

/* fondo */
.box {
    box-sizing: border-box;
    /* margin: 0; */
    min-height: 100%;
    min-width: 280px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var( --color-blueblikz-principal);
}
.box ul {
    list-style: none;       
}

.btn, .btn1, .btn2{
    width: 100%;
    /* height: 28px; */
    margin-top: 25px;
    border: none;
    color: white;
    cursor: pointer;
    /* font-family: 'Segoe UI', Roboto; */
    background-color: var( --color-blueblikz-principal);
}

.btn1{
    margin-top: 50px;
}

.iconS{
    height: 650px;
}

.iconLog{
    height: 500px;
}

/* boton logout */
.btn2 {
    background-color: var( --color-blueblikz-principal);
    border-style: solid;
    border-color: transparent;
    margin-top: 280px;
}

/* iconos */
.btn img , .btn1 img {   
    display:flex;
    justify-content: space-between;
    height: 45px;
    padding: 9px;
    margin: 40px;
}

.btn, .btn1 {
    display: flex;
    align-items: center;
    height: 45px;
}

.btn2 {
    background-color: var(--color-blueblikz-btnhover);
    border-radius: 12px;
    height: 35px;
    width: 230px;
}


.btn:hover, .btn1:hover {
    background-color: var(--color-blueblikz-btnhover);
    width: 255px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.active {
    background-color: var(--color-blueblikz-btnhover);
    width: 255px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);

}

.btn2:hover {
    background-color: #4c67c9;
}

/* .iconH {
    justify-self: start;    
} */

ul li {
    width: 100%;
}

span {
    justify-content: center;
}

@media screen and (max-width: 1080px){
    .gral{
        display: none;
    }
}