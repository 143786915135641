.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.596);
    backdrop-filter: blur(7px);
    z-index: 99999999;
  }
  
  .confirm_box {
    background: rgb(255,255,255);
      background: linear-gradient(207deg, rgba(255,255,255,1) 24%, rgba(207,207,207,1) 100%);  display: flex;
      flex-flow: wrap;
    width: 450px;
    padding: 70px 20px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 9px 13px -2px #9e9e9e;
      box-shadow: 0px 9px 13px -2px #969696;
  }
  .confirm_box h3 {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    color: rgb(21, 21, 21);
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  
  .confirm_box img{
    margin: 0 auto;
    margin-bottom: 50px !important;
  }
  
  
  .btns_div{
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin: 0 auto;
  }
  
  .btns_div button{
      border: none;
      border-radius: .25rem;
      cursor: pointer;
      margin: 0 15px;
      margin-top: 30px !important;
      margin-bottom: 15px !important;
      width: 130px;
      background-color: var(--color-blueblikz-principal);
      color: white;
      padding: 10px;
  }
  .btns_div button:hover {
    background-color: var(--color-blueblikz-btnhover);
    color: #fff;
  }
  .btns_div button:active {
    position: relative;
    top: 2px;
  }
  