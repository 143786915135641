/* 
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
 } */
 
 /* body {
     width: 100%;
     min-height: 100vh;
     background-color: transparent;
     color: black;
     display: flex;
     justify-content: center;
     align-items: center;
     /* margin-top: 50px; */

 .container{
     /* width: 69vw; */
     /* max-width: 69vw;   */
     position: relative;
     background-color: transparent;
     border-radius: 20px;
     padding: 15px;
     display: flex;
     justify-content: center;
 }
 
 .title {
     width: 70vw;
     margin: 80px 15vw 20px 15vw;
     /* padding-top: 20px; */
     font-size: 20px;
     font-weight: bold;
     /* background-color: aliceblue; */
     /* border-top: 1px solid #ddd; */
 }
 
 .cart{
     margin-right: 36px;
     display: flex;
     flex-direction: row;
 }
 
 .container .carousel{
     display: flex;
     overflow-x: auto;
     scroll-behavior: smooth;
     position: relative;
     width: 65vw;
     padding-left: 13px;
 }

 .container .carousel::-webkit-scrollbar{
     display: none;
 }
 
 .container .carousel .item{
     background-color: white;
     margin: 10px;
     padding: 10px;
     width: 270px;
     justify-content: space-around;
     
 
 }
 
 .btnIzq{
     background: none;
     border: none;
     /* position: absolute; */
     /* top: 110px; */
     /* display: flex; */
     /* justify-content: space-between;  */
     /* left: 0; */
     /* bottom: 0; */
     /* margin-right: 50px; */
 }
 
 .btnDer{
     background: none;
     border: none;
     /* position: absolute; */
     /* top: 110px; */
     /* display: flex; */
     /* justify-content: space-between;  */
     /* right: 0;
     bottom: 0; */
 
 }
 
 /* .buttons img{
     height: 70px;
     width: 70px;
 } */
 
 .btn{
     /* display: flex; */
     /* justify-content: space-between; */
     margin-top: 15px;
     justify-content: center;
     /* flex-direction: row; */
 }
 
 
 
 
 
 /* .container .carousel .item .image img{
     width: 100%;
     height: 100%;
     object-fit: cover;
 }
 
 .container .carousel .item .info{
     height: 140px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
 }
 
 .container .carousel .item .info span{
     display: block;
     text-align: center;
     color: black;
     padding: 5px;
     border-radius: 10px;
 } */
 