.gral {
  display: flex;
  flex-direction: row;
}

.buttons {
  background-color: white;
  height: 130px;
  width: 160px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.buttons img {
  margin-bottom: 20px;
}

.buttons:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 249);
}

.buttonProductos {
  background-color: white;
  height: 130px;
  width: 160px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.buttonProductos img {
  margin-bottom: 20px;
}

.buttonProductos:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 249);
}

.popup {
  position: fixed;
  top: -13px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  backdrop-filter: blur(7px);
}

.close1 {
  position: absolute;
  right: 0;
  margin-right: 30px;
  margin-top: -2%;
  cursor: pointer;
  background-color: transparent;
  /* color: rgb(182, 182, 182); */
  border: none;
  font-size: 23px;
  font-weight: 400;
}

.form1 {
  width: 100vw;
  height: 100vh;
  padding: 40px;
  margin: auto;
  margin-top: 3rem;
  /* border-radius: 20px;*/
  color: black;
  font-size: 14px;
  margin-bottom: 20px;
  box-shadow: 0px 9px 13px -2px #6d6d6d;
  background-color: white;
  position: relative;
}

.titleFlota {
  margin-top: 20px;
  margin-bottom: 120px;
  font-size: 26px;
  font-weight: 500;
}

.btnVhDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnVhDiv button {
  max-height: 125px;
  max-width: 125px;
  height: 125px;
  width: 125px;
  cursor: pointer;
  margin-top: 15px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
}
.btnVhDiv button img {
  max-height: 125px;
  max-width: 125px;
  /* height: 100%;
  width: 100%; */
  border-radius: 15px;
  border: none;
}

.btnVhDiv button:hover {
  background-color: #e6e6e6;
}

.btnVh {
  border: none;
  border-radius: 12px;
  margin: 0 20px;
}

.btnVh img {
  margin: auto 0;
}

@media screen and (max-width: 780px){
.buttonProductos{
 display: none;
}
.buttons{
  margin: 0 10px;
}
}