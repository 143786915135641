.gral {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.module {
  flex-direction: column;
}
.module h3 {
  font-weight: 400;
  font-size: 17px;
}
.repartidorImg {
  margin-bottom: 8px;
}
.negocio {
  margin-top: 12px;
  margin-bottom: 8px;
}
.casa {
  margin-top: 12px;
  margin-bottom: 8px;
}
.progressBar {
  width: 100px;
  height: 17px;
  background-color: #b8c4ee;
  margin-bottom: 10px;
  overflow: hidden;
}
.progress {
  height: 100%;
  width: 100%;
  background-color: #4d6fe9;
  animation: progressAnimation 2s linear infinite; /* Animación de 2 segundos infinitos */
}

@keyframes progressAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
