.Card {
  background-color: rgb(24, 24, 24);
  border-radius: 15px;
  width: 200px;
  text-decoration: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 70px;
  margin-right: 15px;
}

.Card:hover {
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.name {
  height: 40px;
  color: white;
  text-decoration: none;
  padding-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 16px;
}

.price,
.currentregprice,
.currentofferprice {
  color: white;
  text-decoration: none;
  padding-top: 15px;
  font-size: 16px;
}

.currentregprice {
  font-size: 20px;
  font-weight: bold;
}

.currentofferprice {
  margin-top: 5px;
  font-size: 20px;
  font-weight: bold;
}

.crossed {
  color: white;
  font-size: 16px;
  text-decoration: line-through;
}

.bothprices {
  margin-top: 10px;
}

a {
  text-decoration: none;
}

.image {
  border-color: rgb(0, 0, 0);
  border-width: 0.5px;
  border-bottom-width: 0px;
}

.sinPedidos {
  font-weight: 500;
  margin: 0 auto;
}
