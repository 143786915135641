.container {
  background-color: rgba(44, 10, 10, 0.726);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 350px;
  height: 150px;
  color: #333;
  background: #e0e0e0;
  /* box-shadow: 13px -13px 21px #636363, -13px 13px 21px #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 15px;
  z-index: 9999;
}
