.gral{
    margin-top: 40px;
}
.downloadDiv{
display: flex;
flex-direction: column;
margin-top: 90px;
}
.nro{
    font-size: 23px;
    margin-top: 20px;
}