.loader {
  /* background-color: rgba(17, 14, 14, 0.726);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3328;
}
