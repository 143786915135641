* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gral{
  /* width: 100%; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  /* margin: auto 0 !important; */
  /* background-image: url("../../assets/fondopagina.png");
  background-position: center; */
}

.form {
  position: relative;
  background-color: white;
  width: 400px;
  height: 500px;
  /* display:flexbox; */
  /* justify-content: center; */
  border-radius: 20px;
  box-shadow: 0 9px 16px rgba(83, 83, 83, 0.493);

}

.form small {
  color: rgb(236, 228, 228);
  width: 100%;
  animation-name: error;
  animation-duration: 3.1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: error;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes error {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form img{
  /* margin: 0 auto; */
  margin-top: 50px;
}

.title {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: black;
  margin-bottom: 40px !important;
  font-size: 21px;
  font-weight: 400;
  /* margin-left: 5px;*/
  margin-top: 27px !important;
  /* padding: 10px ; */
  padding-top: 10px;
  /* padding-right: 30px; */
  /* margin-right: 68px; */
}

.form input {
  border: solid 1.2px;
  border-color: rgb(164, 164, 164);
  border-radius: 13px;
  width: 320px;
  margin-bottom: 15px;
  height: 36px;
  padding-left:15px;
  font-size: 16px;
  outline: none;
}

.btn {
  margin-top: 40px;
  border-style: none;
  border-radius: 8px;
  cursor: pointer;
  width: 130px;
  height: 35px;
  background-color: var(--color-blueblikz-principal);
  color: white;
}

.btn:hover{
  background-color: var(--color-blueblikz-btnhover);
}
.registrate{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  font-size: 14px;
  /* background-color: var(--color-blueblikz-principal);
  color: white;
  border-radius: 4px;
  height: 25px;
  width: 70%;
  justify-content: center; */
  /* border: 0.5px solid rgb(195, 195, 195); */
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
}
.linkRegistro{
  margin-left: 5px;
  font-size: 14px;
  /* color: white; */
  text-decoration: underline;
}
.link {
  margin: 0 auto;
  font-size: 14px;
  color: var(--color-blueblikz-principal);
  margin-top: 15px !important;
  font-weight: 400;
}

.restPassword {
  display: flex;
  flex-direction: column;
  margin-top: -15px;
  /* justify-content: flex-end; */
}
.iconContainer {
  position: relative;
}
.icon {
  position: absolute;
  right: 55px;
  /* top: 20px; */
  bottom: 58%;
  transform: translateY(30%);
  cursor: pointer;
  height: 17px;
}

.iconErrors {
  position: absolute;
  right: 15px;
  top: 35%;
  transform: translateY(30%);
  cursor: pointer;
  height: 20px;
}

@media screen and (max-width: 1087px) {
  .form{
    height: 470px !important;
  }
}

@media screen and (max-width: 430px) { 
  .form{
    width: 350px;
  }
  
  .form input{
    width: 280px;
  }
}