.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    backdrop-filter: blur(7px);
  }
  
  .divTitle h1 {
      color: var(--color-blueblikz-principal);
      margin-bottom: 25px;
      font-size: 27px;
      /* margin: 0 auto; */
      /* margin-left: 105px; */
      /* margin-top: 0px; */
      margin-left: 20px
    }
  
  .divTitle {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 40px; */

  }
  
  .close {
    width: 23px;
    height: 24px;
    border-radius: 5px;
    margin-top: 27px!important;
    cursor: pointer;
    background-color: transparent;
    color: rgb(182, 182, 182) !important;
    /* border-style: thin; */
    border: none;
    font-size: 33px;
    font-weight: 400 !important;
  }
  
  .close:hover {
    color: rgba(231, 53, 53, 0.877) !important;
  }
  
  .gral {
      width: 1000px;
      padding: 40px;
      margin: 30px auto;
      /* margin-top: 3rem; */
      border-radius: 20px;
      color: black;
      font-size: 14px;
      margin-bottom: 20px;
      box-shadow: 0px 9px 13px -2px #6d6d6d;
      background-color: white;
    }
  
    .container{
      display: flex;
      flex-direction: row;
    }

    .col1{
      margin-left: 20px;
      text-align: left;
    }

    .col2{
      margin-left: 80px;
      text-align: left;
    }

    .datos{
        display: flex;
        flex-direction: row;
        align-content: center;
    }

    .datos h3{
        margin-right: 10px;
        margin-bottom: 25px;
    }

    .datos h4{
        font-weight: 400;
        font-size: 16px;
    }
   
    .cancelar{
      position: fixed;
      top: 30px;
      right: 240px;
      background-color: rgb(219, 24, 24) !important;
      border: none;
      color: white !important;
      height: 45px;
      width: 145px;
      border-radius: 10px;
      /* margin-top: 10px; */
      cursor: pointer;
    }

    .cancelar:hover{
      background-color: rgb(188, 17, 17) !important;
      color: white !important;
    }

    .cancelado{
      position: fixed;
      top: 30px;
      right: 240px;
      background-color: rgb(219, 24, 24) !important;
      border: none;
      color: white !important;
      height: 45px;
      width: 145px;
      border-radius: 10px;
      cursor: pointer;
    }

    .cancelado:hover{
      background-color: rgb(219, 24, 24) !important;
      color: white !important;
      cursor:auto !important;
    }

