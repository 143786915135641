* {
  font-family: "Montserrat";
}

.main {
  background-color: rgb(244, 244, 244);
}

.header{
  display: none;
}

.directAccess {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
}

.misEnvios {
  color: black;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: 500;
  display: flex;
  justify-content: left;
  margin-left: 70px;
  margin-top: 50px;
}

.shopInfo {
  display: flex;
  justify-content: center;
  /* margin: 20px 0; */
}

.messageDesktop {
  display: flex;
  justify-content: center;
}

.messageMobile {
  display: none;
}

.title {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  margin-top: 40px;
}

.title button {
  background-color: var(--color-blueblikz-principal);
  color: white;
  border: none;
  height: 43px;
  width: 170px;
  /* margin: 0 15px; */
  border-radius: 13px;
  font-weight: 500;
  cursor: pointer;
  /* position: relative; */
  padding-left: 16px;
}

.title button:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.title h2 {
  font-family: "Montserrat";
  font-weight: 500;
  color: white;
  position: absolute;
  right: 188px;
  top: 257px;
  cursor: pointer;
}

.title h1 {
  display: flex;
  width: 78%;
  justify-content: flex-start;
  color: black;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: 500;
  padding-left: 48px;
  padding-top: 15px;
  /* padding-right: 300px; */
  /* margin-right: 40px !important; */
  margin: auto 0;
  /* margin-top: 40px; */
  /* margin-bottom: 50px; */
  /* margin-bottom: 40px; */
}

.chartscontainer {
  margin-top: 70px;
  margin-left: -140px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-around;
  /* background: #fff; */
}

.chartcontainer,
.alerts {
  /* width: 800px; */
  width: 50vw;
  border: 1px solid #eee;
  border-radius: 5px;
  background: #fff;
}

.adminShipping {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* margin-bottom: 100px; */
}

.alerts {
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
}

.alert {
  background: #fff;
  width: 48%;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: left;
}

.alert .text {
  padding: 15px;
  font-size: 12px;
}

.alert .text span {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.chartcontainer .title {
  padding: 10px;
  width: 50vw;
  color: #fff;
  background: var(--color-principal-violet);
  border-radius: 5px 5px 0 0;
}

.chartcontainer .chart1 {
  padding: 50px;
  width: 50vw;
}
.chartcontainer .chart2 {
  padding: 50px 100px;
  width: 50vw;
}

.btnall button {
  background-color: var(--color-blueblikz-principal);
  color: white;
  border: none;
  height: 43px;
  width: 170px;
  margin: 0 15px;
  border-radius: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 20px;
}

.btnall button:hover {
  background-color: var(--color-blueblikz-btnhover);
}

.mobile_div {
  margin: 0 auto;
}

.sliderMobile{
  display: none;
}

@media screen and (max-width: 1150px) {
  .searchbar {
    width: 760px;
    margin: 0 auto;
  }
  /* .searchbar h1{
    margin-left: 30px;
  } */
}

/* @media screen and (max-width: 850px){
  .searchbar{
    width: 600px;
}
} */

@media screen and (max-width: 780px) {
  .searchbar {
    width: 740px;
  }
  .main {
    /* background-color: rgb(244, 244, 244); */
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0 9px 16px rgba(179, 178, 178, 0.493);
  }
  .gral {
    background-color: var(--color-blueblikz-principal);
    margin-top: -1px;
  }
  .header{
    display: block; 
  }
}

@media screen and (max-width: 750px) {
  .searchbar {
    width: 600px;
  }
}

@media screen and (max-width: 650px) {
  .searchbar {
    width: 500px;
  }

  .searchbar h1 {
    width: 160px;
  }
}

@media screen and (max-width: 500px) {
  .searchbar {
    width: 100%;
    justify-content: center;
  }
  .searchbar h1 {
    margin: 0 auto;
  }
  .misEnviosMobile {
    font-size: 20px;
    font-weight: 500;
  }
  .tableDesktop {
    display: none;
  }
  .sliderMobile {
    margin-top: 40px;
    display: block;
  }
  .messageDesktop {
    display: none;
  }
  .messageMobile {
    display: flex;
    justify-content: center;
  }
}
